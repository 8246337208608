import { useContext, useState } from "react";
import SideMenu from '../components/SideMenu/SideMenu';
import { ReactComponent as Flame } from '../assets/icons/flame.svg';
import { ReactComponent as Human } from '../assets/icons/human.svg';
import { ReactComponent as Calendar } from '../assets/icons/24.svg';
import Tab1 from './Tab1/Tab1';
import Tab2 from './Tab2/Tab2';
import Tab3 from './Tab3/Tab3';
import { useTranslation } from "react-i18next";
import ActionPlanContext from "../contexts/ActionPlanContext";
import { ICustomSelectOption } from "../components/CustomSelect";
import { useNavigate, useParams } from "react-router-dom";
import { IToastItem } from "../components/Toast";
import { saveActionPlan } from "../helpers/api";

const Modal = ({onClose}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        position: 'absolute',
        inset: 0,
        backgroundColor: '#000000DD',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: 300,
          backgroundColor: '#FFFFFF',
          borderRadius: 20,
          padding: 25,
          fontSize: 18,
        }}
      >
        <span>{t('actionPlan.popup.text')}</span>
        <div
          className="button_submit"
          style={{
            margin: 0,
          }}
        >
          <button
            type="button"
            className="App-saveBtn"
            onClick={onClose}
            style={{
              cursor: 'pointer',
              marginTop: 20,
            }}
          >
            <span>{t('actionPlan.popup.button')}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const ActionPlanFormDev = () => {
  const [selectedTab, setSelectedTab] = useState(null);
  const { actionPlan, setActionPlan } = useContext(ActionPlanContext);
  const [data, setData] = useState(actionPlan || {});
  const { t } = useTranslation();
  const [summary, setSummary] = useState(actionPlan?.summary || '');

  const dateObj = actionPlan?.nextMeetingAt
    ? new Date(actionPlan.nextMeetingAt)
    : '';

  const [date, setDate] = useState(
    dateObj
      ? `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getDate().toString().padStart(2, '0')}`
      : ''
  );

  const [treatment, setTreatment] = useState(actionPlan?.treatmentEffects || '');
  const [severityOpt, setSeverityOpt] = useState(actionPlan?.severity || '');
  const [saving, setSaving] = useState(false);
  const { actionPlanId } = useParams();
  const [toast, setToast] = useState<IToastItem | null>(null);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const navigate = useNavigate();

  // const severityOptions = useMemo(() => getSeverityOptions(t), [i18n.language]);
  const onSendData = async (e) => {
    setLoading(true);
    e.preventDefault();
    const {
      // @ts-ignore
      _id,
      // @ts-ignore
      version,
      // @ts-ignore
      user,
      // @ts-ignore
      profile,
      // @ts-ignore
      physicianEmailHash,
      // @ts-ignore
      status,
      // @ts-ignore
      pdfFilename,
      // @ts-ignore
      pdfCreatedAt,
      // @ts-ignore
      xlsxFilename,
      // @ts-ignore
      zipFilename,
      // @ts-ignore
      zipCreatedAt,
      // @ts-ignore
      updatedAt,
      ...cleanPayload
    } = data;    
    const payload = { ...cleanPayload, summary };
    // @ts-ignore
    if (severityOpt) payload.severity = severityOpt;
    // @ts-ignore
    if (date) payload.nextMeetingAt = date;
    // @ts-ignore
    if (treatment) payload.treatmentEffects = treatment;

    try {
      setSaving(true);
      //@ts-ignore
      delete payload?.id;
      //@ts-ignore
      delete payload?.profileId;
      //@ts-ignore
      delete payload?.physicianName;
      //@ts-ignore
      delete payload?.updatedAt;
      //@ts-ignore
      delete payload?.firstDaysLow?._id;
      //@ts-ignore
      delete payload?.firstDaysLow?.emollient?._id;
      //@ts-ignore
      delete payload?.followUpModerate?._id;
      //@ts-ignore
      delete payload?.followUpModerate?.emollient?._id;
      //@ts-ignore
      delete payload?.followUpModerate?.antiInflammatory?._id;
      //@ts-ignore
      delete payload?.firstDaysHigh?._id;
      //@ts-ignore
      delete payload?.firstDaysHigh?.antiInflammatoryBody?._id;
      //@ts-ignore
      delete payload?.firstDaysHigh?.antiInflammatoryFace?._id;
      //@ts-ignore
      delete payload?.followUpLow?._id;
      //@ts-ignore
      delete payload?.followUpLow?.emollient?._id;
      //@ts-ignore
      delete payload?.followUpHigh;
      //@ts-ignore
      delete payload?.otherAilments;
      //@ts-ignore
      delete payload?.firstDaysHigh?.medication;

      const updatedActionPlan = await saveActionPlan(actionPlanId, payload);
      setActionPlan(updatedActionPlan);
      setToast({
        message: t('actionPlan.actionPlanSaved'),
        color: 'green',
      });
      setTimeout(() => {
        setActionPlan(null);
        navigate('/wap-completed');
      }, 1000);
    } catch (err) {
      setToast({
        message: t('actionPlan.actionPlanSaveError', {
          errMessage: err.message,
        }),
        color: 'red',
      });
      setLoading(false);
    } finally {
      setSaving(false);
    }
  };

  const onChangeData = (
    formKey: string,
    itemKey: string,
    fieldKey: string,
    value: string
  ) => {
    setData((prevData) => ({
      ...prevData,
      [formKey]: {
        // @ts-ignore
        ...(prevData[formKey] || {}),
        [itemKey]: {
          // @ts-ignore
          ...(prevData[formKey]?.[itemKey] || {}),
          [fieldKey]: value,
        },
      },
    }));
  };

  const tabs = [
    {
      title: t('actionPlan.sections.firstDays'),
      icon: <Flame />,
      component: (
        <Tab1
          data={data}
          onChange={onChangeData}
        />
      ),
      id: 0,
    },
    {
      title: t('actionPlan.sections.followUp'),
      icon: <Calendar />,
      component: (
        <Tab2
          // @ts-ignore
          data={data?.followUpModerate || {}}
          onChange={onChangeData}
        />
      ),
      id: 1,
    },
    {
      title: t('actionPlan.sections.afterDays'),
      icon: <Human />,
      component: (
        <Tab3
          // @ts-ignore
          data={data?.followUpLow || {}}
          onChange={onChangeData}
        />
      ),
      id: 2,
    },
  ];
  return (
    <div>
      <div
        style={{
          display: 'flex',
          width: '100%',
        }}
      >
        <SideMenu
          tabs={tabs}
          selectedTab={selectedTab}
          onChangeTab={setSelectedTab}
          onChangeSeverity={setSeverityOpt}
          onChangeTreatment={setTreatment}
          onChangeDate={setDate}
          date={date}
          setData={setData}
          data={data}
          onSend={onSendData}
          readyToSend={!loading}
          disabled={!treatment || !severityOpt}
        />
        {selectedTab !== null && tabs[selectedTab].component}
      </div>
      {modalIsOpen && <Modal onClose={() => setModalIsOpen(false)} />}
    </div>
  );
};

export default ActionPlanFormDev;
