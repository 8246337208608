import React, { useContext, useEffect, useState } from 'react';
import './SideMenu.css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ActionPlanContext from '../../contexts/ActionPlanContext';
import { saveActionPlan } from '../../helpers/api';
import { ReactComponent as Chevron } from '../../assets/icons/chevron.svg';
import { ReactComponent as PdfIcon } from '../../assets/icons/pdf-icon.svg';
import { ReactComponent as XlsIcon } from '../../assets/icons/xls.svg';
import { ReactComponent as ZipIcon } from '../../assets/icons/zip.svg';
import Loader from '../Loader';

const DownloadPDFSection = () => {
  const { actionPlan } = useContext(ActionPlanContext);
  if(!actionPlan) return null;
  const pdfLink = `/api/action-plans/${actionPlan._id}/${actionPlan.pdfFilename}`;
  const pdfPublicFilename = `EczemaCARE-plus--${actionPlan._id}--${actionPlan.pdfFilename}`;

  return (
    <div className="button_submit">
      <a
        href={pdfLink}
        download={pdfPublicFilename}
        className="App-saveBtn"
        style={{
          marginTop: 0,
          marginBottom: 0,
          textDecoration: 'none',
        }}
      >
        <PdfIcon />
        <span>{t('actionPlan.downloadPDF')}</span>
      </a>
    </div>
  );
};

const DownloadZIPSection = () => {
  const { actionPlan } = useContext(ActionPlanContext);
  if(!actionPlan) return null;
  const zipLink = `/api/action-plans/${actionPlan._id}/${actionPlan.zipFilename}`;
  const zipPublicFilename = `EczemaCARE-plus--${actionPlan._id}--${actionPlan.zipFilename}`;

  return (
    <div className="button_submit">
      <a
        href={zipLink}
        download={zipPublicFilename}
        className="App-saveBtn"
        style={{
          marginTop: 0,
          marginBottom: 0,
          textDecoration: 'none',
        }}
      >
        <ZipIcon />
        <span>{t('actionPlan.downloadZIP')}</span>
      </a>
    </div>
  );
};

const DownloadXLSXSection = () => {
  const { actionPlan } = useContext(ActionPlanContext);
  if(!actionPlan) return null;
  const xlsxLink = `/api/action-plans/${actionPlan._id}/${actionPlan.xlsxFilename}`;
  const xlsxPublicFilename = `EczemaCARE-plus--${actionPlan._id}--${actionPlan.xlsxFilename}`;

  return (
    <div className="button_submit">
      <a
        href={xlsxLink}
        download={xlsxPublicFilename}
        className="App-saveBtn"
        style={{
          marginTop: 0,
          marginBottom: 0,
          textDecoration: 'none',
        }}
      >
        <XlsIcon />
        <span>{t('actionPlan.downloadXLSX')}</span>
      </a>
    </div>
  );
};

const SideMenu = ({
  tabs,
  onChangeTab,
  onSend,
  onChangeSeverity,
  onChangeDate,
  date,
  onChangeTreatment,
  setData,
  data,
  readyToSend = false,
  disabled,
}: any) => {
  const { t, i18n } = useTranslation();
  const dateFormat = (date: Date) => new Intl.DateTimeFormat(i18n.language).format(date);
  const { actionPlan } = useContext(ActionPlanContext);
  const [dateToStub, setDateToStub] = useState(dateFormat(new Date()));
  const [resume, setResume] = useState('');
  const { actionPlanId } = useParams();

  useEffect(() => {
    setDateToStub(dateFormat(new Date()));
  }, [setDateToStub, i18n.language]);

  const handleSelectedTab = (selectedTab: number) => {
    onChangeTab(selectedTab);
  };

  const severityValues = [
    { value: '', label: t('actionPlan.select') },
    { value: 'low', label: t('actionPlan.severities.minor') },
    { value: 'moderate', label: t('actionPlan.severities.moderate') },
    { value: 'high', label: t('actionPlan.severities.high') },
  ];
  const treatmentEffectsValues = [
    { value: '', label: t('actionPlan.select') },
    { value: 'controlled', label: t('actionPlan.treatmentEffects.controlled') },
    { value: 'uncontrolled', label: t('actionPlan.treatmentEffects.uncontrolled') },
  ];

  const meetingToStub = data.meetingHistory || [];

  const addResume = async (resumeText: string) => {
    const payload = {
      meetingHistory: [
        ...meetingToStub,
        { createdAt: new Date(), content: resumeText },
      ],
    };
    try {
      const updatedActionPlan = await saveActionPlan(actionPlanId, payload);
      setData(prev => ({
        ...prev,
        meetingHistory: updatedActionPlan.meetingHistory,
      }));
    } catch (err) {
      // TODO
    }
  };

  return (
    <div className="SideMenu">
      <div className="SideMenu_container">
        {data?.pdfFilename && <DownloadPDFSection />}
        {data?.zipFilename && <DownloadZIPSection />}
        {data?.xlsxFilename && <DownloadXLSXSection />}
        <div className="dropdown_container">
          <div className="dropdown">
            <p>{t('actionPlan.illnessSeverity')} <span style={{color: '#FF0000'}}>*</span></p>
            <select
              name="severity"
              onChange={(event) => onChangeSeverity(event.target.value)}
              className="option_select"
              style={{ height: '30px', borderRadius: '20px', padding: 5, textAlign: 'center' }}
            >
              {severityValues?.map((severity, index) => (
                <option
                  className="option_selected"
                  key={index.toString()}
                  value={severity.value}
                  disabled={index === 0}
                  selected={severity.value === data.severity || (!data.severity && index === 0)}
                >
                  {severity.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="dropdown_container">
          <div className="dropdown">
            <p>{t('actionPlan.treatmentEffect')} <span style={{color: '#FF0000'}}>*</span></p>
            <select
              name="treatment"
              onChange={(event) => onChangeTreatment(event.target.value)}
              className="option_select"
              style={{ height: '30px', borderRadius: '20px', padding: 5, textAlign: 'center' }}
            >
              {treatmentEffectsValues?.map((severity, index) => (
                <option
                  className="option_selected"
                  key={index.toString()}
                  value={severity.value}
                  disabled={index === 0}
                  selected={severity.value === data.treatmentEffects || (!data.treatmentEffects && index === 0)}
                >
                  {severity.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <ul className="itemsList">
          {tabs.map((tab, index: number) => (
            <li
              className="item"
              onClick={() => {
                handleSelectedTab(index);
              }}
              key={index.toString()}
            >
              <div className="menu_container">
                <div className="icon">{tab.icon}</div>
                <div className="title">
                  <p>{tab.title}</p>
                </div>
                <div className="chevron">
                  <Chevron />
                </div>
              </div>
            </li>
          ))}
        </ul>
        <div className="datepicker">
          <label className="label" htmlFor="datepicker">{t("actionPlan.selectDateNextMeeting")} :</label>
          <input
            type="date"
            name="datepicker"
            id="datepicker"
            className="datepicker_input"
            onChange={(event) => onChangeDate(event.target.value)}
            value={date}
          />
        </div>
        <div className="datepicker">
          {meetingToStub.map((e, index) => (
            <div key={index.toString()}>
              <span className="summary-consultation">{t('actionPlan.summaryDate')} {dateFormat(new Date(e.createdAt))}</span>
              <p>{e.content}</p>
            </div>
          ))}
        </div>
        <div className="datepicker">
          <span className="summary-consultation">{t('actionPlan.summary')}</span>
          <p>{dateToStub}</p>
          <label htmlFor="">
            {t('actionPlan.summaryDate')} {dateToStub}
          </label>
          <textarea
            name="meetingHistory.content"
            id=""
            value={resume}
            className="datepicker_input"
            placeholder="Résumé pour la consultation"
            onChange={(event) => setResume(event.target.value)}
          />
        </div>
        <div>
          <button
            type="button"
            className="App-saveBtn add-resume-button"
            onClick={async (event) => {
              await addResume(resume);
              setResume('');
            }}
            style={{
              cursor: 'pointer',
            }}
          >
            <span>{t('actionPlan.addResume')}</span>
          </button>
        </div>
        <div style={{color: '#FF0000', fontStyle: 'italic', marginTop: 50}}>* {t('actionPlan.requiredField')}</div>
        <div className="button_submit" style={{marginTop: 0}}>
          <button
            type="button"
            className="App-saveBtn"
            disabled={!readyToSend || disabled}
            onClick={(event) => onSend(event)}
            style={{
              cursor: 'pointer',
            }}
          >
            {!readyToSend
              ? <Loader/>
              : (
                <>
                  <div
                    style={{
                      width: 35,
                      height: 35,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <svg
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      transform="scale(.75)"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.10135 9.6575V34.9353H34.3791V14.7636L29.273 9.6575H9.10135ZM7.29579 6.04639H30.768L37.4612 12.7396C37.7998 13.0781 37.9901 13.5373 37.9902 14.0161V36.7408C37.9902 37.2197 37.8 37.6789 37.4614 38.0175C37.1228 38.3562 36.6635 38.5464 36.1847 38.5464H7.29579C6.81693 38.5464 6.35768 38.3562 6.01907 38.0175C5.68046 37.6789 5.49023 37.2197 5.49023 36.7408V7.85194C5.49023 7.37308 5.68046 6.91383 6.01907 6.57522C6.35768 6.23661 6.81693 6.04639 7.29579 6.04639ZM21.7402 33.1297C20.3036 33.1297 18.9259 32.559 17.9101 31.5432C16.8942 30.5274 16.3236 29.1496 16.3236 27.7131C16.3236 26.2765 16.8942 24.8987 17.9101 23.8829C18.9259 22.8671 20.3036 22.2964 21.7402 22.2964C23.1768 22.2964 24.5546 22.8671 25.5704 23.8829C26.5862 24.8987 27.1569 26.2765 27.1569 27.7131C27.1569 29.1496 26.5862 30.5274 25.5704 31.5432C24.5546 32.559 23.1768 33.1297 21.7402 33.1297ZM10.9069 11.4631H27.1569V18.6853H10.9069V11.4631Z"
                        fill="#77C8D2"
                      />
                    </svg>
                  </div>
                  <span>{t('actionPlan.save')}</span></>
              )
            }
          </button>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
